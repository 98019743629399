<template>
    <section class="">
        <div class="row mx-0 align-items-center border-bottom mb-2 py-2">
            <div class="col-auto">
                <div class="bg-general3 text-white br-50 d-middle-center" style="width:20px;height:20px;" @click="$router.go(-1)">
                    <i class="icon-left f-20" />
                </div>
            </div>
            <div class="col-auto text-general f-600 f-18">
                Editar datos de la cuenta
            </div>
            <div class="col-auto px-4 ml-auto mx-2 py-1 cr-pointer text-white bg-general br-10" @click="updateDatos">
                Guardar
            </div>
        </div>
        <div class="alto-content custom-scroll">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 pl-4">
                    <div class="col-12 text-general f-17 f-400 f-15">
                        Los datos tu perfil, facturación y ubicación requiere revisión y aprobación de leeche en caso que los cambies, este proceso puede tardar hasta 24 horas.*
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <div class="border-black d-middle-center br-50" style="width:40px;height:40px;">
                            <i class="icon-leechero-cap text-general f-20" />
                        </div>
                    </div>
                    <div class="col-auto text-general d-middle px-0 f-600">
                        Tu perfil
                    </div>
                </div>
                <div class="row mx-0 px-5 my-2">
                    <div class="col-auto">
                        <slim-cropper ref="foto_cedis" :options="slimOptions" class="border foto_leechero" />
                        <p class="text-general f-14 text-center my-1">
                            Foto <i>(Opcional)</i>
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="text-general f-12 mt-2">
                            Nombre*
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:20" name="nombre">
                            <el-input v-model="model.prop_nombre" placeholder="Ej. Ana Maria" maxlength="20" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                        <p class="text-general f-12 mt-2">
                            Apellido*
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:20" name="apellido">
                            <el-input v-model="model.prop_apellido" placeholder="Ej. Ana Maria" maxlength="20" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <div class="border-black d-middle-center br-50" style="width:40px;height:40px;">
                            <i class="icon-pedidos text-general f-20" />
                        </div>
                    </div>
                    <div class="col-auto text-general d-middle px-0 f-600">
                        Facturación
                    </div>
                </div>
                <div class="row mx-4">
                    <div class="col-12 text-general f-15 px-5">
                        ¿Desea que generemos factura a su nombre o el de su tienda? Llene a continuación su información legal (Datos opcionales)
                    </div>
                    <div class="col-12 px-5 my-2 d-middle">
                        <input v-model="model.tipo" :value="1" type="radio" class="option-input radio mx-1" /> Persona natural
                        <input v-model="model.tipo" :value="2" type="radio" class="option-input radio mx-1 ml-3" /> Tienda
                    </div>
                    <template v-if="model.tipo == 1">
                        <div class="col-7 my-3 pl-5">
                            <ValidationProvider v-slot="{errors}" rules="numeric|max:20" name="NIT">
                                <p class="ml-2">Número de documento de identidad*</p>
                                <el-input v-model="model.nit" maxlength="20" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        <!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-5 my-3 pl-5">
                            <ValidationProvider v-slot="{errors}" rules="max:60" name="nombre">
                                <p class="ml-2">Nombre de la tienda</p>
                                <el-input v-model="model.nombre" maxlength="60" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        <!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
                        </div>
                        <div class="col-5 my-3 pl-5">
                            <ValidationProvider v-slot="{errors}" rules="numeric|max:20" name="NIT">
                                <p class="ml-2">Nit de la tienda*</p>
                                <el-input v-model="model.nit" maxlength="20" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        <!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
                        </div>
                    </template>
                    <div class="col-7 my-3 pl-5">
                        <el-upload
                        ref="adjuntos"
                        class="upload-demo w-100"
                        action="#"
                        :multiple="false"
                        :limit="1"
                        :on-change="agregar_archivos"
                        :auto-upload="false"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        >
                            <div class="upload-documents w-100 text-center py-3 bg-light-f5 br-5 cr-pointer pt-1">
                                Subir PDF o Imagen
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <div class="border-black d-middle-center br-50" style="width:40px;height:40px;">
                            <i class="icon-pedidos text-general f-20" />
                        </div>
                    </div>
                    <div class="col-auto text-general d-middle px-0 f-600">
                        Tu ubicación
                    </div>
                </div>
                <div class="row mx-0 px-5">
                    <div class="col-5">
                        <p class="pl-3 f-14 text-general">
                            Dirección*
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="dirección">
                            <el-select
                            v-model="model.direccion"
                            filterable
                            remote
                            class="w-100"
                            reserve-keyword
                            :remote-method="remoteMethod"
                            @change="getDetails()"
                            >
                                <el-option
                                v-for="item in options"
                                :key="item.place_id"
                                :label="item.description"
                                :value="item.description"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-5">
                        <p class="pl-3 f-14 text-general">
                            Codigo postal (Opcional)*
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="max:40" name="código postal">
                            <el-input v-model="model.codigo_postal" maxlength="40" class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-5 my-3">
                        <p class="pl-3 f-14 text-general">
                            Piso, apartamento o local (Opcional)*
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="max:40" name="complemento">
                            <el-input v-model="model.direccion_anexo" maxlength="40" class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-5 my-3">
                        <p class="pl-3 f-14 text-general">
                            Guardar como*
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="guardar como" vid="guardar_como">
                            <el-input v-model="model.guardar_como" maxlength="30" class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-10">
                        <p class="text-general pl-3 f-14">
                            Ubicación en el mapa*
                        </p>
                        <mapa ref="mapaPeq" :coordenadas="coordenadas" :buscar="model.direccion" @actualizar="actualizarCoordenadas" />
                        <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                            Haga click aquí, para seleccionar la ubicación de su tienda
                        </p>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <div class="border-black d-middle-center br-50" style="width:40px;height:40px;">
                            <i class="icon-pencil-outline text-general f-20" />
                        </div>
                    </div>
                    <div class="col-auto text-general d-middle px-0 f-600">
                        Acerca de ti
                    </div>
                </div>
                <div class="row mx-0 px-5">
                    <div class="col-5">
                        <p class="text-general pl-3 f-14">
                            ¿Cuándo naciste?
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="fecha de nacimiento">
                            <el-date-picker v-model="model.propietario_nacimiento" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-7 d-flex align-items-end pb-2">
                        <input v-model="model.propietario_genero" :value="2" type="radio" class="option-input black radio mx-1" /> Mujer
                        <input v-model="model.propietario_genero" :value="1" type="radio" class="option-input black radio mx-1 ml-3" /> Hombre
                        <input v-model="model.propietario_genero" :value="3" type="radio" class="option-input black radio mx-1 ml-3" /> Otro
                    </div>
                    <!-- <div class="col-12 my-3">
                        <p class="text-general f-15 pl-3"> Cuénta a leeche sobre ti </p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:300" name="descripción">
                            <el-input v-model="model.descripcion_mi" type="textarea" :rows="6" maxlength="300" class="w-100" placeholder="Dinos quien eres" />
                            <span class="text-danger f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div> -->
                    <!-- <div class="col-12 my-3">
                        <p class="text-general f-15 pl-3"> Mi descripción (Esta información la podrán ver tus clientes) </p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:300" name="descripción cliente">
                            <el-input v-model="model.descripcion" type="textarea" :rows="6" maxlength="300" class="w-100" placeholder="Describete, cúentale a tus clientes quien eres. como los quieres entender" />
                            <span class="text-danger f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div> -->
                </div>
            </ValidationObserver>
        </div>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        :buscar="model.direccion"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        @cancelar="cancelarMapa()"
        />
        <modal-no-direccion ref="modalNoDireccion" @change="onChangeModal" />
    </section>
</template>

<script>
import DatosTienda from "~/services/configurar/tendero-vip/datos_tienda";
import localizacion from '~/services/localizacion';
// import Localizaciones from "~/services/proyectos/localizaciones";

export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen de perfil',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },
            model: {
                barrio:null,
                descripcion:null,
                descripcion_mi:null,
                direccion:null,
                direccion_anexo:null,
                estado:null,
                id:null,
                idm_ciudad:null,
                idm_estado:null,
                idm_pais:null,
                latitud:0,
                logo:null,
                logo_mini:null,
                longitud:0,
                nit:null,
                nit_soporte:null,
                nombre:null,
                codigo_postal:null,
                tipo:1,
                prop_nombre:null,
                prop_apellido:null,
                propietario_nombre:null,
                propietario_genero: 3,
                propietario_nacimiento: null,
                tienda: false,
            },
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            archivo: [],
            fileList: [],
            options: [],
            location: {
                city: '',
                state: '',
                country: '',
                postalCode: '',
                direction: '',
            },
            postulacion:{
                id: null,
                postulacion: null,
                idm_pais: null,
            },
            tienda: null
        }
    },
    computed: {
        getDireccionCon(){
            // const { ciudad = '' } = this.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            // const { nombre: pais = '' } = this.paises.find(x => x.id === this.model.idm_pais) || {};
            return [this.model.direccion ].filter(el => el).join(',')
        },
    },
    watch: {
        location(val){
            console.log(val);
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            await this.cargarDatos()
            await this.remoteMethod(this.model.direccion)
            await this.getDetails()
            await this.paisPostulacion()
        },
        async onChangeModal(direccion){
            this.model.direccion = direccion
            await this.remoteMethod(direccion)
            await this.getDetails()
        },
        async getLocationFromBrowser(){
            try {
                let googleMaps = await this.$iniciarGoogleMaps();
                this.location = await localizacion.getLocationFromBrowser(googleMaps);
            } catch (error){
                this.error_catch(error)
            }
        },
        async cargarDatos(){
            try {
                const { data } = await DatosTienda.getDatosTienda(this.$usuario.tienda.id)
                this.model = {...data.data}

                this.coordenadas.lng = +this.model.longitud
                this.coordenadas.lat = +this.model.latitud
                this.$refs.mapaPeq.setPosition()
                this.model.idm_ciudad = data.data.idm_ciudad

                this.model.tienda = data.data.nombre !== null ? true : false

                this.location.city = data.data.ciudad
                this.location.state = data.data.departamento
                this.location.country = data.data.pais

                // this.model.direccion = [this.model.direccion, this.location.city, this.location.state, this.location.country ].filter(el => el).join(',')

                if (this.model.logo !== null){
                    this.$refs.foto_cedis.set_image(`${this.model.logo_firmado}`)
                } else {
                    this.$refs.foto_cedis.instanciaCrop.remove()
                }
                this.fileList = []
                if (this.model.nit_soporte_firmado){
                    this.fileList.push({
                        name: 'Ajunto nit',
                        url: this.model.nit_soporte_firmado
                    })
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async paisPostulacion(){
            try {
                const { data } = await DatosTienda.paisPostulacion(this.$usuario.tienda.id)

                this.postulacion = data.postulacion
                this.tienda = data.tienda

            } catch (e){
                this.error_catch(e)
            }
        },
        handleRemove(file, fileList){
            this.archivo = []
            this.fileList = []
            this.$refs.adjuntos.clearFiles()
        },
        async agregar_archivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                tipo = 1
                tamano = 20
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
            } else if (file.raw.type === 'video/mp4'){
                tipo = 3
                tamano = 20
            } else {
                tipo = 4
                tamano = 25
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple los requisitos', 'warning')
                return
            }

            if (tipo == 3 || tipo == 4){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imagenes)', 'warning')
                return
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
                return
            }

            this.archivo.push(file.raw)
        },
        async updateDatos(){
            try {
                this.paisPostulacion()
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.logo = archivo.image
                this.model.logo_mini = null
                this.model.tipoAdmin = this.$tienda.estado != 5 ? 1 : this.postulacion.postulacion == 1 ? 2 : 1
                this.model.id_tienda = this.$usuario.tienda.id
                this.model.propietario_nombre = `${this.model.prop_nombre} ${this.model.prop_apellido}`

                if (this.archivo.length){
                    this.model.nit_soporte = this.archivo[0]
                }else if (this.fileList.length){
                    this.model.nit_soporte = 'si hay'
                }else{
                    this.model.nit_soporte = null
                }

                let formData = this.crear_formData(this.model)

                const {data} = await DatosTienda.updateDatos(formData)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('actualizar')
                // if (this.tienda){
                //     this.$refs.adjuntos.clearFiles()
                //     this.archivo = []
                // }
                this.$router.push({name: 'tendero.conf.registro-completado', params: {
                    tipo: this.model.tipoAdmin == 1 ? 2 : 1
                }})
            } catch (e){
                this.error_catch(e)
            }
        },
        abrirModalMapa(){
            // this.$refs.modalContinuarRegistro.toggle();
            this.$refs.modalMapaGoogle.toggle()
        },
        cancelarMapa(){
            // this.$refs.modalContinuarRegistro.toggle();
        },
        async actualizarCoordenadasCiudad({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
            let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();

            const obj = await localizacion.getLocationFromLatLng(googleMaps, latitud, longitud);
            console.log(obj);
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.model.codigo_postal = +obj.postalCode
            this.model.direccion = obj.direction
            this.model.ciudad = obj.city
            this.model.departamento = obj.state
            this.model.pais = obj.country
            this.$refs.mapaPeq.setPosition()
            this.options = []

        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        },
        async remoteMethod(query){
            try {
                let googleMaps = await this.$iniciarGoogleMaps();
                this.options = await localizacion.getPlaces(googleMaps, query);
                this.options.push({place_id: 'no_direccion', description: 'No aparece mi dirección'})
            } catch (e){
                this.options = [];
                this.options.push({place_id: 'no_direccion', description: 'No aparece mi dirección'})
            }
        },
        async getDetails(){
            let { place_id = '' } = this.options.find(el => el.description == this.model.direccion) ?? {}

            if(!place_id) return

            if(place_id == 'no_direccion'){
                this.$refs.modalNoDireccion.toggle()
                return 
            }

            let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();
            let geocoder = new googleMaps.Geocoder();

            geocoder.geocode({
                'placeId': place_id
            },
            async(responses, status) => {
                if (status == 'OK'){
                    let lat = responses[0].geometry.location.lat();
                    let lng = responses[0].geometry.location.lng();
                    this.coordenadas = {lat, lng}
                    this.$refs.mapaPeq.setPosition()
                    this.model.latitud = lat
                    this.model.longitud = lng

                    const obj = await localizacion.getLocationFromLatLng(googleMaps, lat, lng);
                    this.model.codigo_postal = +obj.postalCode
                    this.model.ciudad = obj.city
                    this.model.departamento = obj.state
                    this.model.pais = obj.country
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.foto_leechero{
    height:150px;
    background:#FAFAFA;
    width:150px;
    border-radius:12px;
}
.upload-documents{
    border: 1px dashed var(--color-general2);
}
</style>
