var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center"},[_c('p',{staticClass:"text-general f-18"},[_vm._v("Domicilios")])]),_c('div',{staticClass:"p-3 f-15 custom-scroll alto-content",staticStyle:{"height":"calc(100vh - 151px)"}},[_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col-xl-5 col-lg-8 col-md-10 col-sm-12"},[_c('label',{staticClass:"text-general"},[_vm._v("Cobertura de tu tienda (metros)")]),_c('ValidationProvider',{attrs:{"rules":"required|numeric|max:5","name":"cobertura"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input-miles',{attrs:{"money-options":_vm.config_distancias},nativeOn:{"blur":function($event){return _vm.CrearEditarCobertura(valid)}},model:{value:(_vm.model.cobertura),callback:function ($$v) {_vm.$set(_vm.model, "cobertura", $$v)},expression:"model.cobertura"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col-xl-5 col-lg-8 col-md-10 col-sm-12"},[_c('label',{staticClass:"text-general"},[_vm._v("Indique el valor mínimo del pedido para que el domicilio sea gratis")]),_c('ValidationProvider',{attrs:{"rules":"required|numeric|max:8","name":"domicilio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input-miles',{nativeOn:{"blur":function($event){return _vm.CrearEditarDomicilio(valid)}},model:{value:(_vm.model.domicilio_gratis),callback:function ($$v) {_vm.$set(_vm.model, "domicilio_gratis", $$v)},expression:"model.domicilio_gratis"}}),(_vm.model.domicilio_gratis == 0)?_c('p',{staticClass:"text-general f-12"},[_vm._v("En su tienda se visualizará con domicilios gratis")]):_vm._e(),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('p',{staticClass:"m-3 text-general f-15 f-500"},[_vm._v("Valor del domicilio")]),_vm._l((_vm.distancia),function(data,idx){return _c('div',{key:idx,staticClass:"row mx-0 my-3"},[(data.id != 0)?_c('div',{staticClass:"col-xl-5 col-lg-8 col-md-10 col-sm-12"},[_c('label',{staticClass:"text-general"},[_vm._v("De "+_vm._s(data.distancia_inicial)+" a "+_vm._s(data.distancia)+" metros de distancia")]),_c('ValidationProvider',{attrs:{"rules":"required|numeric|max:5","name":"domicilio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input-miles',{nativeOn:{"blur":function($event){return _vm.CrearEditarTiendaDistancia(data,valid)}},model:{value:(data.valor),callback:function ($$v) {_vm.$set(data, "valor", $$v)},expression:"data.valor"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_c('div',{staticClass:"col-xl-5 col-lg-8 col-md-10 col-sm-12"},[_c('label',{staticClass:"text-general"},[_vm._v(" "+_vm._s(data.distancia_inicial)+" metros de distancia")]),_c('ValidationProvider',{attrs:{"rules":"required|numeric|max:5","name":"domicilio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input-miles',{nativeOn:{"blur":function($event){return _vm.CrearEditarTiendaDistancia(null,valid)}},model:{value:(_vm.valor_final),callback:function ($$v) {_vm.valor_final=$$v},expression:"valor_final"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }