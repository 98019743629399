<template>
    <section>
        <div class="row mx-0 px-xl-3 px-lg-3 px-md-3 px-sm-0 px-0 pl-xl-5 pl-lg-5 pl-md-5 pl-sm-5 pl-0 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
            <div class="col px-0 tres-puntos">
                <p class="text-general f-18 tres-puntos">Datos de la tienda</p>
            </div>
            <div v-if="tienda.id_tienda == null ? true : tienda.estado != 1" class="bg-general cr-pointer f-500 br-10 f-16 px-3 py-1 text-white" @click="$router.push({name: 'tendero.conf.datosTienda.editar'})">
                Editar datos
            </div>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <div v-if="tienda.id_tienda == null ? false : tienda.estado == 1" class="row mx-0 my-3 justify-content-center f-12 align-items-center">
                <i class="icon-attention-alt f-18" />
                <div class="col">
                    <p>Los cambios realizados se encuentran en revisión, este tramite puede tardar hasta "24 Horas". Cambios realizados: {{ formatearFecha(tienda.created_at,'Y-MM-DD - hh:mm A') }}</p>
                </div>
            </div>
            <div v-if="tienda.id_tienda == null ? false : tienda.estado == 1" class="row mx-0 my-2">
                <p>
                    Los datos editados y que están pendientes por aprobación se distinguen por la siguiente imagen
                    <i class="icon-alert-triangle f-18 text-orange" />
                </p>
            </div>
            <div class="row mx-0">
                <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 my-lg-2 my-md-2 text-center px-0">
                    <img :src="tienda.logo_firmado" class="br-12 border obj-cover" style="width:162px;height:162px;" />
                </div>
                <div class="col-xl col-lg-12 col-md-12 col-sm-12">
                    <p class="mb-0 text-general f-600 f-22">
                        {{
                            (tienda.estado != 3 && (tienda.prop_nombre != null || tienda.prop_apellido != null))?
                                `${tienda.prop_nombre ? tienda.prop_nombre : tienda.prop_nombre_original } ${tienda.prop_apellido ? tienda.prop_apellido : tienda.prop_apellido_original }` :
                                `${tienda.propietario_nombre}`
                        }}
                        <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && (tienda.prop_nombre != null || tienda.prop_apellido != null)" placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                <p class="text-general2 f-14 text-center">
                                    Anteriormente:
                                </p>
                                <p class="text-general f-14 text-center my-1">
                                    {{ tienda.propietario_nombre }}
                                </p>
                            </div>
                            <i class="icon-alert-triangle f-18 text-orange" />
                        </el-tooltip>
                    </p>
                    <p class="text-general mt-2">
                        <strong> Fecha nacimiento: </strong>
                        {{ tienda.propietario_nacimiento }}
                    </p>
                    <p class="text-general mt-2">
                        <strong> Genero: </strong>
                        {{ tienda.genero_nombre }}
                    </p>
                    <div class="row mx-0 my-3" />
                    <p class="text-general mt-2">
                        <strong> Contacto </strong>
                    </p>
                    <p class="text-general mt-2">
                        <strong> Número de celular: </strong>
                        {{ tienda.telefono_celular }}
                    </p>
                    <p class="text-general mt-2">
                        <strong>Dirección de correo electrónico: </strong>
                        {{ tienda.correo }}
                    </p>
                    <!-- Descripcion -->
                    <div class="row mx-0 my-2">
                        <div class="col-auto px-0 f-600 text-general">
                            Descripción:
                        </div>
                        <div class="col text-general">
                            {{ (tienda.estado != 3 && tienda.descripcion != null) ? tienda.descripcion : tienda.descripcion_original }}
                            <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.descripcion != null" placement="bottom" effect="light">
                                <div slot="content" style="max-width:200px;">
                                    <p class="text-general2 f-14 text-center">
                                        Anteriormente:
                                    </p>
                                    <p class="text-general f-14 text-center my-1">
                                        {{ tienda.descripcion_original }}
                                    </p>
                                </div>
                                <i class="icon-alert-triangle f-18 text-orange" />
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- Descripcion -->
                    <div class="row mx-0 my-4" />

                    <!-- <p class="text-general mt-2">
                        <strong> Ubicación </strong>
                    </p>
                    <p class="text-general mt-2">
                        <strong> {{ (tienda.estado != 3 && tienda.idm_ciudad != null) ? tienda.pais : tienda.pais_original }}, </strong>
                        {{ (tienda.estado != 3 && tienda.idm_ciudad != null) ? tienda.departamento : tienda.departamento_original }},
                        {{ (tienda.estado != 3 && tienda.idm_ciudad != null) ? tienda.ciudad : tienda.ciudad_original }}
                        <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.idm_ciudad != null" placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                <p class="text-general2 f-14 text-center">
                                    Anteriormente:
                                </p>
                                <p class="text-general f-14 text-center my-1">
                                    <strong> {{ tienda.pais_original }}, </strong>
                                    {{ tienda.departamento_original }},
                                    {{ tienda.ciudad_original }}
                                </p>
                            </div>
                            <i class="icon-alert-triangle f-18 text-orange" />
                        </el-tooltip>
                    </p>
                    <p class="text-general mt-2">
                        <strong> Código postal: </strong>
                        {{ tienda.codigo_postal }}
                    </p>
                    <p class="text-general mt-2">
                        <strong> Dirección: </strong>
                        {{ (tienda.estado != 3 && tienda.direccion != null) ? tienda.direccion : tienda.direccion_original }}
                        <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.direccion != null" placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                <p class="text-general2 f-14 text-center">
                                    Anteriormente:
                                </p>
                                <p class="text-general f-14 text-center my-1">
                                    {{ tienda.direccion_original }}
                                </p>
                            </div>
                            <i class="icon-alert-triangle f-18 text-orange" />
                        </el-tooltip>
                    </p>
                    <p class="text-general mt-2">
                        <strong> Complemento: </strong>
                        {{ (tienda.estado != 3 && tienda.direccion_anexo != null) ? tienda.direccion_anexo : _.defaultTo(tienda.direccion_anexo_original,'No registra') }}
                        <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.direccion_anexo != null" placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                <p class="text-general2 f-14 text-center">
                                    Anteriormente:
                                </p>
                                <p class="text-general f-14 text-center my-1">
                                    {{ _.defaultTo(tienda.direccion_anexo_original,'No registra') }}
                                </p>
                            </div>
                            <i class="icon-alert-triangle f-18 text-orange" />
                        </el-tooltip>
                    </p>
                    <div class="row mx-0 mt-5">
                        <div class="col">
                        </div>
                    </div>
                    <div class="row mx-0 position-relative my-3">
                        <div class="col p-0 px-0 cr-pointer" @click="abrirModalMapa">
                            <mapa ref="mapaPeq" :coordenadas="coordenadas" />
                            <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                                Haga click aquí, para ver su ubicación
                                <i v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.direccion != null" class="icon-alert-triangle f-18 text-orange" />
                            </p>
                        </div>
                    </div>
                    <p class="text-general mt-4">
                        <strong> Barrio: </strong>
                        {{ (tienda.estado != 3 && tienda.barrio != null) ? tienda.barrio : _.defaultTo(tienda.barrio_original,'No registra') }}
                        <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.barrio != null" placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                <p class="text-general2 f-14 text-center">
                                    Anteriormente:
                                </p>
                                <p class="text-general f-14 text-center my-1">
                                    {{ _.defaultTo(tienda.barrio_original,'No registra') }}
                                </p>
                            </div>
                            <i class="icon-alert-triangle f-18 text-orange" />
                        </el-tooltip>
                    </p> -->
                    <!-- <div class="row mx-0 my-2">
                        <div class="col">
                        </div>
                    </div> -->
                    <p class="text-general mt-2">
                        <strong> Facturación </strong>
                    </p>
                    <template v-if="tienda.tipo == 1">
                        <p class="text-general mt-2">
                            Como persona natural
                        </p>
                        <div class="row mx-0 mt-2">
                            <div class="col">
                                <p class="text-general mt-2">
                                    <strong> N.I.T de la tienda: </strong>
                                    {{ (tienda.estado != 3 && tienda.nit != null) ? tienda.nit : _.defaultTo(tienda.nit_original,'No registra') }}
                                    <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.nit != null" placement="bottom" effect="light">
                                        <div slot="content" style="max-width:200px;">
                                            <p class="text-general2 f-14 text-center">
                                                Anteriormente:
                                            </p>
                                            <p class="text-general f-14 text-center my-1">
                                                {{ _.defaultTo(tienda.nit_original,'No registra') }}
                                            </p>
                                        </div>
                                        <i class="icon-alert-triangle f-18 text-orange" />
                                    </el-tooltip>
                                </p>
                            </div>
                        </div>
                        <div v-if="tienda.nit_soporte" class="row mx-0 px-5 my-2">
                            <div id="df_gbp" class="bg-general3 br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(tienda.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda)">
                                <i class="icon-doc-inv" />
                                Adjunto: Soporte Nit
                            </div>
                        </div>
                    </template>
                    <template v-else-if="tienda.tipo == 2">
                        <p class="text-general mt-2">
                            Como Tienda
                        </p>
                        <div class="row mx-0 mt-2">
                            <div class="col">
                                <p class="text-general mt-2">
                                    <strong> Tienda: </strong>
                                    {{ tienda.nombre }}
                                </p>
                                <p class="text-general mt-2">
                                    <strong> N.I.T de la tienda: </strong>
                                    {{ (tienda.estado != 3 && tienda.nit != null) ? tienda.nit : _.defaultTo(tienda.nit_original,'No registra') }}
                                    <el-tooltip v-if="tienda.id_tienda != null && tienda.estado != 3 && tienda.nit != null" placement="bottom" effect="light">
                                        <div slot="content" style="max-width:200px;">
                                            <p class="text-general2 f-14 text-center">
                                                Anteriormente:
                                            </p>
                                            <p class="text-general f-14 text-center my-1">
                                                {{ _.defaultTo(tienda.nit_original,'No registra') }}
                                            </p>
                                        </div>
                                        <i class="icon-alert-triangle f-18 text-orange" />
                                    </el-tooltip>
                                </p>
                            </div>
                        </div>
                        <div v-if="tienda.nit_soporte" class="row mx-0 px-5 my-2">
                            <div id="df_gbp" class="bg-general3 br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(tienda.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda)">
                                <i class="icon-doc-inv" />
                                Adjunto: Soporte Nit
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="tienda.id_tienda == null && tienda.estado == 3" class="row m-3 br-10 border align-items-center px-2 justify-content-between">
                <p class="f-22 f-600" :class="`text-general`">Cambios Solicitados: Denegado</p>
                <p> {{ formatearFecha(tienda.updated_at,'dddd DD MMM Y') }} </p>
            </div>
            <div class="row m-2 px-3 text-general2">
                {{ tienda.justificacion }}
            </div>
        </div>
        <modalEditarDatos ref="modalEditarDatos" @actualizar="getDatos" />
        <!-- <modalVerMapa ref="modalVerMapa" /> -->
        <!-- <mapa-google-modal
        ref="modalMapaGoogle"
        :coordenadas-entrada="coordenadas"
        :desactivar="true"
        /> -->
        <modal-imagen ref="modal_imagen" />
    </section>
</template>

<script>
import DatosTienda from "../../../../services/configurar/tendero-vip/datos_tienda";

export default {
    components: {
        modalEditarDatos: () => import('./modalEditarDatos'),
        // modalVerMapa: () => import('./modalVerMapa')
    },
    data(){
        return {
            model: { longitud: '', latitud: '' },
            tienda: {
                barrio: null,
                barrio_original: null,
                created_at: null,
                ciudad_original: null,
                departamento: null,
                departamento_original: null,
                descripcion: null,
                descripcion_original: null,
                direccion: null,
                direccion_original: null,
                direccion_anexo: null,
                direccion_anexo_original: null,
                estado: null,
                id: null,
                id_tienda: null,
                idm_ciudad: null,
                idm_ciudad_original: null,
                idm_estado: null,
                idm_pais: null,
                justificacion: null,
                latitud: null,
                latitud_original: null,
                logo: null,
                logo_mini: null,
                logo_mini_firmado: null,
                longitud: null,
                longitud_original: null,
                nit: null,
                nit_original: null,
                nit_soporte: null,
                nombre: null,
                prop_nombre: null,
                prop_nombre_original: null,
                prop_apellido: null,
                prop_apellido_original: null,
                propietario_nombre: null,
                propietario_genero: null,
                propietario_nacimiento: null,
                telefono_celular: null,
                updated_at: null,
            },
            coordenadas: { lat: 0, lng: 0 }
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await DatosTienda.getDatos(this.$usuario.tienda.id)
                this.tienda = data.data
                this.coordenadas.lng = this.tienda.longitud == null ? +this.tienda.longitud_original : +this.tienda.longitud
                this.coordenadas.lat = this.tienda.latitud == null ? +this.tienda.latitud_original : +this.tienda.latitud

                this.$refs.mapaPeq.setPosition()
            } catch (e){
                this.error_catch(e)
            }
        },
        editarDatos(){
            this.$refs.modalEditarDatos.toggle(this.tienda)
        },
        verMapa(){
            console.log('primero');
            // this.$refs.modalVerMapa.toggle()
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        openAdjunto(item){
            if(this.file_type(item.nit_soporte) == 'application/pdf'){
                this.set_pdf(item.nit_soporte_firmado)
            }else if(this.file_type(item.nit_soporte) == 'image/*'){
                this.$refs.modal_imagen.toggle(item.nit_soporte_firmado)
            }
        },
    }
}
</script>

<style lang="css" scoped>
.text-orange{
    color: #FF612B;
}
</style>
