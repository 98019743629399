import axios from "axios"

const API = "datos-acceso";
const API_VINCULAR = "cliente"

const datosAcceso = {
    getDatos(id_tienda){
        return axios(`${API}/${id_tienda}/get-datos`)
    },
    updateDatos(id_tienda, params){
        return axios.put(`${API}/${id_tienda}/update-datos`, params)
    },
    fetchDatos: id => axios.get(`${API}/${id}/fetch-datos`),
    
    // Para vinculación
    vincularSocial: data => axios.post(`${API_VINCULAR}/vincular-google`, data),
    vincularEnviarCodigo: data => axios.post(`${API_VINCULAR}/vincular-enviar-codigo`, data),
    vincularCheckUsuario: data => axios.post(`${API_VINCULAR}/vincular-check-usuario`, data),
    checkVincularCodigoTemporal: data => axios.post(`${API_VINCULAR}/ckeck-vincular-codigo-temporal`, data),
    vincularPassword: data => axios.post(`${API_VINCULAR}/vincular-password`, data),
    vincularFromFirebase: data => axios.post(`${API_VINCULAR}/vincular-from-firebase`, data),
}

export default datosAcceso
