<template>
    <section>
        <div class="row mx-0">
            <menuPrincipal :menus="menus" />
            <div class="px-0 border br-10 ml-3 bg-white">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    metaInfo:()=>({ title: 'Configurar Tendero' }),
    middleware:['auth'],
    data(){
        return {
            activo: 'datosTienda',
            menus: [
                { nombre: 'Datos de la tienda', ruta: 'tendero.conf.datosTienda', icon: 'icon-storefront-outline' },
                { nombre: 'Domicilios', ruta: 'tendero.conf.domicilios', icon: 'icon-bike' },
                { nombre: 'Pedidos', ruta: 'tendero.conf.pedidos', icon: 'icon-receipt' },
                { nombre: 'Datos de acceso', ruta: 'tendero.conf.datos-acceso', icon: 'icon-lock' },
                { nombre: 'Galeria', ruta: 'tendero.conf.galeria', icon: 'icon-image-outline' }
            ]
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    height: calc(100vh - 94px);
    background: #fff;
}
</style>
