<template>
    <section class="mx-3 mt-2 border d-middle-center custom-scroll overflow-auto" style="height:calc(100vh - 59px);">
        <div class="col-6 text-center">
            <img :src="url_image" class="obj-cover" style="max-width:100%;height:calc(100vh - 315px);" />
            <p class="text-general f-600 f-17 text-center my-3">
                ¡Genial!
            </p>
            <p class="text-general2 f-17 text-center my-3 px-5">
                <template v-if="$route.params.tipo == 2">
                    Estás a un paso de iniciarte como {{$config.vendedor}}, en breve un asesor se pondrá en contacto contigo para activarte.
                </template>
                <template v-else>
                    Ahora estás listo para disfrutar la nueva manera de vender.
                </template>
            </p>
            <div v-if="$route.params.tipo == 2" class="row mx-0 my-4 justify-center">
                <div class="col-auto">
                    <div class="bg-general text-white br-10 text-center px-5 py-1 cr-pointer" @click="checkout">
                        Ir al inicio
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border-general2 text-general br-10 text-center px-4 py-1 cr-pointer" @click="soporte">
                        Hablar con un asesor
                    </div>
                </div>
            </div>
            <template v-if="$route.params.tipo == 1">
                <div class="row mx-0 justify-center">
                    <div class="bg-general text-white br-10 text-center py-1 cr-pointer" style="width:250px;" @click="checkout">
                        Ir al inicio
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            url_image: '/img/ilustraciones/registro_completo.svg'
        }
    },
    methods: {
        checkout(){
            this.$router.push({name: 'tendero.carrito'})
        },
        soporte(){
            this.$router.push({name: 'tendero.ayuda.contactar'})
        },
    }
}
</script>
<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
</style>
