<template>
    <section>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
                <p class="text-general f-18">Domicilios</p>
            </div>
            <div class="p-3 f-15 custom-scroll alto-content" style="height:calc(100vh - 151px)">
                <div class="row mx-0 my-3">
                    <div class="col-xl-5 col-lg-8 col-md-10 col-sm-12">
                        <label class="text-general">Cobertura de tu tienda (metros)</label>
                        <ValidationProvider v-slot="{errors, valid}" rules="required|numeric|max:5" name="cobertura">
                            <input-miles v-model="model.cobertura" :money-options="config_distancias" @blur.native="CrearEditarCobertura(valid)" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-xl-5 col-lg-8 col-md-10 col-sm-12">
                        <label class="text-general">Indique el valor mínimo del pedido para que el domicilio sea gratis</label>
                        <ValidationProvider v-slot="{errors, valid}" rules="required|numeric|max:8" name="domicilio">
                            <input-miles v-model="model.domicilio_gratis" @blur.native="CrearEditarDomicilio(valid)" />
                            <p v-if="model.domicilio_gratis == 0" class="text-general f-12">En su tienda se visualizará con domicilios gratis</p>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <p class="m-3 text-general f-15 f-500">Valor del domicilio</p>
                <div v-for="(data, idx) in distancia" :key="idx" class="row mx-0 my-3">
                    <div v-if="data.id != 0" class="col-xl-5 col-lg-8 col-md-10 col-sm-12">
                        <label class="text-general">De {{ data.distancia_inicial }} a {{ data.distancia }} metros de distancia</label>
                        <ValidationProvider v-slot="{errors,valid}" rules="required|numeric|max:5" name="domicilio">
                            <input-miles v-model="data.valor" @blur.native="CrearEditarTiendaDistancia(data,valid)" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div v-else class="col-xl-5 col-lg-8 col-md-10 col-sm-12">
                        <label class="text-general"> {{ data.distancia_inicial }} metros de distancia</label>
                        <ValidationProvider v-slot="{errors,valid}" rules="required|numeric|max:5" name="domicilio">
                            <input-miles v-model="valor_final" @blur.native="CrearEditarTiendaDistancia(null,valid)" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import TenderoDomicilio from '~/services/configurar/tendero/domicilios'
export default {
    data(){
        return {
            model:{
                cobertura:0,
                domicilio_gratis:0,
            },
            config_distancias: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' m',
                precision: 0,
                masked: false
            },
            valor_final:0,
            distancia:[],
            distancia_final:null
        }
    },
    mounted(){
        this.listarTenderoDomicilios()
        this.listarDistancias()
    },
    methods:{
        async listarTenderoDomicilios(){
            try {
                const {data} = await TenderoDomicilio.GetTenderoDomicilio(this.$usuario.tienda.id)
                this.model.cobertura =  data.cobertura
                this.model.domicilio_gratis =  data.domicilio_gratis
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarDistancias(){
            try {
                const {data} = await TenderoDomicilio.GetTiendasDistancia({tienda: this.$usuario.tienda.id})
                let indice = data.distancia.length -1
                this.distancia_final = `${data.distancia[indice].distancia}`
                this.valor_final = `${data.distancia[indice].tienda_distancia.valor}`
                data.distancia.forEach(element => {
                    element.valor =  element.tienda_distancia ? element.tienda_distancia.valor : 0
                });
                this.distancia =  data.distancia
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearEditarCobertura(valid){
            try {
                if (valid){
                    let datos ={
                        id:this.$usuario.tienda.id,
                        cobertura:this.model.cobertura,
                    }
                    const {data} = await TenderoDomicilio.PostCrearEditarCobertura(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listarTenderoDomicilios()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearEditarDomicilio(valid){
            try {
                if (valid){
                    let datos ={
                        id:this.$usuario.tienda.id,
                        domicilio_gratis:this.model.domicilio_gratis
                    }
                    const {data} = await TenderoDomicilio.PostCrearEditarDomicilio(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listarTenderoDomicilios()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearEditarTiendaDistancia(event,valid){
            try {
                // return console.log(event);
                // return
                if (valid){
                    let datos ={
                        id_distancia:event ? event.id :null,
                        id_tienda:this.$usuario.tienda.id,
                        valor:event ? event.valor : this.valor_final,

                    }
                    const {data} = await TenderoDomicilio.PostCrearEditarTiendaDistancia(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listarDistancias()

                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
