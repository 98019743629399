<template>
    <section>
        <div class="row mx-0 border-bottom px-3 py-2">
            <div class="col-auto text-general f-600 f-18 ">
                Galeria
            </div>
        </div>
        <div class="custom-scroll overflow-auto alto-content">
            <div class="row mx-0 px-3 py-3 position-relative">
                <cargando v-if="cargando" />
                <div class="col-auto">
                    <el-upload
                    action="#"
                    :auto-upload="false"
                    :before-upload="validarImagenCargada"
                    :on-change="capturarSubidaImagenes"
                    list-type="picture-card"
                    accept="image/*"
                    class="upload-galeria my-2 br-12"
                    :multiple="false"
                    :show-file-list="false"
                    >
                        <img slot="default" src="/img/icons/add-image.svg" height="100%" width="100%" />
                    </el-upload>
                </div>
                <div v-for="(data, d) in galeria" :key="d" class="col-auto">
                    <div class="card-image border my-2 position-relative">
                        <i class="icon-cancel-circled position-absolute f-25 cr-pointer" style="right:5px;" @click="handleRemove(data)" />
                        <img :src="data.url" class="br-6 obj-cover" width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Galeria from "~/services/configurar/tendero/galeria";
export default {
    data(){
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            cargando: false,
            galeria: [],
        }
    },
    mounted(){
        this.getGaleria()
    },
    methods: {
        async getGaleria(){
            try {
                this.cargando = true
                const {data} = await Galeria.getGaleria(this.$tienda.id)
                this.galeria =  data.galeria
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        async handleRemove(file){
            try {
                console.log(file);

                const {data} = await Galeria.eliminarGaleria(file.id)
                this.notificacion('', data.mensaje, 'success')

                this.galeria = this.galeria.filter(el => el.id != file.id)

            } catch (e){
                this.error_catch(e)
            }
        },
        validarImagenCargada(archivo){
            const nombreEsCorrecto = archivo.name.length <= 100;
            const esImagen = archivo.type.split('/')[0] === 'image';
            const pesaMenos2M = archivo.size / 1024 / 1024 <= 2;

            if (!esImagen)
                this.notificacion('Inválido', 'Sólo se permiten imágenes', 'error');

            if (!nombreEsCorrecto)
                this.notificacion(
                    'No válido',
                    `¡El nombre del archivo ${archivo.name} excede los 100 caracteres!`,
                    'error'
                );

            if (!pesaMenos2M)
                this.notificacion(
                    'No válido',
                    'El peso del archivo sobrepasa los 2Mb',
                    'error'
                );

            return nombreEsCorrecto && esImagen && pesaMenos2M;
        },
        async capturarSubidaImagenes(archivo, listaArchivos){
            try {
                // console.log(archivo);
                // console.log(this.generarBase64(archivo.raw));
                // return
                this.cargando = true

                let datos = {
                    url: await this.generarBase64(archivo.raw),
                    id_tienda: this.$tienda.id
                }


                const {data} = await Galeria.crearGaleria(datos)
                this.notificacion('', data.mensaje, 'success')

                listaArchivos = [...listaArchivos, archivo]
                this.galeria = [...this.galeria, data.datos];
            } catch (error){
                // listaArchivos = listaArchivos.filter(el => el.uid != archivo.uid)
                // this.galeria = this.galeria.filter(el => el.uid != archivo.uid)
                console.log(error);
            }finally{
                this.cargando = false
            }
        },
        generarBase64: async archivo => {
            const lector = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(archivo);
                reader.onload = () => resolve(reader.result);
            });

            return await lector;
        },
    }
}
</script>
<style lang="scss" scoped>
.card-image{
    width: 148px;
    height: 148px;
    border-radius: 6px;
    img{
        border-radius: 6px;
    }
}
.br-12{border-radius: 12px !important;}
</style>
