import axios from 'axios'

const tenderodomicilio = {

    PostCrearEditarCobertura: (datos) => axios.post(`domicilios/crear-editar-tienda-cobertura`,datos),
    PostCrearEditarDomicilio: (datos) => axios.post(`domicilios/crear-editar-tienda-domicilio`,datos),
    GetTenderoDomicilio: (id) => axios.get(`domicilios/${id}/listar-tienda-domicilios`),
    GetTiendasDistancia: params => axios.get(`domicilios/listar-tienda-distancia`, { params }),
    PostCrearEditarTiendaDistancia: (datos) => axios.post(`domicilios/crear-editar-tienda-distancia`,datos),
    GetTiendasDistanciafinal: (id) => axios.get(`domicilios/${id}/listar-tienda-distancia-final`),
}
export default tenderodomicilio
